import Cta from "../common/cta/cta";
import CategoryArea from "./category-area";
import HeroArea from "./hero-area";
import Pricing from "./pricing";
import HowItWorks from "./How-it-works";
import "./pricing.css";
const index = () => {
  return (
    <>
      {/* hero start */}
      <HeroArea />
      {/* hero end */}

      {/* category start */}
      <CategoryArea />
      {/* category end */}

      {/* banner start */}
      {/* <BannerArea /> */}
      {/* banner end */}

      {/* 
      {<CareersPath />} */}
      {/* <HowItWorks /> */}
      {/* event start */}
      {/* <EventArea /> */}
      {/* event end */}
      {/* Commented till we create blogs and webinars */}
      {/* {<MixEvents/>}  */}

      <div className="row">
        <div className="col-md-3">
          <div className="white-bg">
            <div style={{ paddingTop: "50px" }} className="description">
              Communication Challenge
            </div>
            <div className="image-section">
              <img
                width="300px"
                style={{ borderRadius: "230px" }}
                src="https://lifeskillsbharat.com/images/avatar2.png"
                alt="Illustration"
              />
            </div>
            <div style={{ paddingBottom: "10px" }} className="image-section">
              <a
                className="ctabtn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://communicationtest.lifeskillsbharat.com/"
              >
                Start
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="white-bg">
            <div style={{ paddingTop: "50px" }} className="description">
              Career Interest Test
            </div>
            <div className="image-section">
              <img
                width="300px"
                style={{ borderRadius: "230px" }}
                src="https://lifeskillsbharat.com/images/avatar2.png"
                alt="Illustration"
              />
            </div>
            <div style={{ paddingBottom: "10px" }} className="image-section">
              <a
                className="ctabtn"
                target="_blank"
                rel="noopener noreferrer"
                href="http://careerinterest.lifeskillsbharat.com/"
              >
                Start
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="white-bg">
            <div style={{ paddingTop: "50px" }} className="description">
              Stress Test
            </div>
            <div className="image-section" style={{ paddingTop: 40 }}>
              <img
                width="300px"
                style={{ borderRadius: "230px" }}
                src="https://lifeskillsbharat.com/images/avatar2.png"
                alt="Illustration"
              />
            </div>
            <div style={{ paddingBottom: "10px" }} className="image-section">
              <a
                className="ctabtn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://stresstest.lifeskillsbharat.com/"
              >
                Start
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="white-bg">
            <div style={{ paddingTop: "50px" }} className="description">
              AI Quiz
            </div>
            <div className="image-section" style={{ paddingTop: 40 }}>
              <img
                width="300px"
                style={{ borderRadius: "230px" }}
                src="https://lifeskillsbharat.com/images/avatar2.png"
                alt="Illustration"
              />
            </div>
            <div style={{ paddingBottom: "10px" }} className="image-section">
              <a
                className="ctabtn"
                target="_blank"
                rel="noopener noreferrer"
                href="https://aitest.lifeskillsbharat.com/"
              >
                Start
              </a>
            </div>
          </div>
        </div>
      </div>

      {/* pricing start */}
      <div style={{ marginTop: 40 }}>
        <Pricing />
      </div>
      {/* pricing end */}

      {/* cta start */}
      {/* <Cta /> */}

      {/* cta end */}
    </>
  );
};

export default index;
