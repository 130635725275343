import { useState } from "react";
import styled from "styled-components";

import FinishPage from "../finish";
import SkillPage from "../skill";

import { AssessmentType } from "../../../../redux/types/generic-constant";

import {
  submitAptitudeTest,
  submitSoftSkillTest,
} from "../../../../services/assessment";
import { useDispatch, useSelector } from "react-redux";
import { getQueryParams } from "../../../../helpers";

import { AppConfigEvent } from "../../../../redux/types/redux-event";
import TestHeader from "../test-header/TestHeader";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
`;
const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 50px 70px;
`;
const PageHeader = styled.label`
  display: flex;
  width: 100%;
  font-size: 22px;
  color: #3e3d43;
  margin-bottom: 70px;
`;
const Span = styled.span`
  display: flex;
  width: 100%;
  max-width: 1160px;
  padding-bottom: 30px;
`;
const CheckBoxBlock = styled.div`
  display: flex;
  align-items: flex-start;
`;
const Checkbox = styled.input`
  display: flex;
  margin: 4px 10px 0 0;
  cursor: pointer;
`;
const AcceptText = styled.span`
  display: flex;
  font-size: 14px;
  flex-direction: column;
  color: ${(props) => (props.color === "red" ? "#FF0000" : "#1F1534")};
`;

const BeginBtn = styled.button`
  display: flex;
  max-width: 176px;
  justify-content: center;
  padding: 20px;
  align-items: center;
  border-radius: 60px;
  background: #016738;
  color: #ffffff;
  border: 1px;
  font-weight: 900;
  margin-top: 25px;
  cursor: ${(props) => (props.assessmentId ? "not-allowed" : "pointer")};
  opacity: ${(props) => (props.assessmentId ? "40%" : "100%")};
`;

const AssessmentBeginPage = () => {
  console.log("on assessment begin page");
  // const { , , assessmentType } = useSelector(
  //   ({ assessmentTests: { answerSheet, testId, assessmentType } }) => ({
  //     answerSheet,
  //     testId,
  //     ,
  //   })
  // );

  const answerSheet = useSelector((state) => state.assessment.answerSheet);
  const testId = useSelector((state) => state.assessment.testId);
  const assessmentType = useSelector(
    (state) => state.assessment.assessmentType
  );

  const dispatch = useDispatch();
  // const [isChecked, setCheckBox] = useState<boolean>(false)
  const [assessmentId, setId] = useState(getQueryParams("id") || "");
  const [pageNumb, setPage] = useState(0);
  const [tstId, setTestId] = useState("");
  const [assessmntType, setType] = useState(AssessmentType.APTITUDE);
  const setData = (testId, type, pageNumber) => {
    setTestId(testId);
    setType(type);
    setPage(pageNumber);
  };
  const onTestSubmit = async () => {
    dispatch({ type: AppConfigEvent.START_LOADING });
    if (assessmentType === AssessmentType.APTITUDE) {
      const res = await submitAptitudeTest({ testId: testId, answerSheet });
      console.log("res", res);
      if (!res) return;
      setData(testId, assessmentType, 2);
    } else if (assessmentType === AssessmentType.SOFT_SKILL) {
      const res = await submitSoftSkillTest({ testId: testId, answerSheet });
      if (!res) return;
      setData(testId, assessmentType, 2);
    }
    dispatch({ type: AppConfigEvent.STOP_LOADING });
  };
  return (
    <Container>
      {/* complete later */}
      <TestHeader
        headerConfig={{
          ghLogo: true,
          searchBar: false,
          home: false,
          msg: false,
          notification: false,
          avatarControl: true,
          timeTicker: pageNumb === 0 || pageNumb === 1,
        }}
        onTestSubmit={onTestSubmit}
        pageNum={pageNumb}
      />
      {pageNumb === 0 && (
        <PageContainer>
          <PageHeader> {"Aptitude Assessment"}</PageHeader>
          <Span>
            This aptitude assessment test comprises 48 questions, and you will
            have 60 minutes to answer as many as you can correctly.
          </Span>
          <Span>
            You will have to work quickly and accurately to perform well in this
            test. If you don't know the answer to a question, leave it and come
            back to it if you have time. Each question will have four possible
            answers, one of which is correct.
          </Span>
          <Span>
            You may click Back and Next during the test to review or skip
            questions.You can submit your test at any time.
          </Span>
          <Span>
            If the time limit is up before you click submit, the test will
            automatically be submitted with the answers you have selected. It is
            recommended to keep working until the time limit is up.
          </Span>
          <Span>
            Try to find a time and place where you will not be interrupted
            during the test. The test will start on the next screen.
          </Span>
          {/*<CheckBoxBlock>*/}
          {/*    {' '}*/}
          {/*    <Checkbox type="checkbox" onChange={(e) => setCheckBox(e.target.checked)}/>*/}
          {/*    <AcceptText>*/}
          {/*        I agree with Terms & Condition above.<AcceptText color={'red'}>Please Tick Above to Agree*/}
          {/*        Terms & Condition</AcceptText>*/}
          {/*    </AcceptText>*/}
          {/*</CheckBoxBlock>*/}
          <BeginBtn
            assessmentId={!assessmentId}
            disabled={!assessmentId}
            onClick={() => setPage(1)}
          >
            Begin
          </BeginBtn>
        </PageContainer>
      )}
      {pageNumb === 1 && (
        <SkillPage
          assessmentId={assessmentId}
          assessmentType={AssessmentType.APTITUDE}
          setPage={setData}
        />
      )}
      {pageNumb === 2 && (
        <FinishPage testId={tstId} assessmentType={assessmntType} />
      )}
    </Container>
  );
};

export default AssessmentBeginPage;
