import React, { useEffect, useState } from "react";

import Row from "react-bootstrap/Row";

import "./studentinteractivedashboard.css";

import { Link, useNavigate, useLocation } from "react-router-dom";

import { getDataFromLocalStorage } from "../../browser-storage";
import { toast } from "react-toastify";
import config from "../../services/config";
import { quickInterviewToken } from "../../services/quick-interview";
import styled from "styled-components";
import { razorpayCreateOrder } from "../../services/payment-service";
import { addserviceScheduler } from "../../services/scheduler-service";
import SliderMenu from "./slidermenu";

const StudentInteractiveDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const Path = location.state?.data;
    console.log("Path", Path);
    if (Path) {
      navigate(Path);
    }
  }, []);

  return (
    <>
      <Row style={{ background: "#ffffff" }}>
        <div className="mobile">
          <SliderMenu />
        </div>
        <div className="desktop">
          <SliderMenu />
        </div>
      </Row>
    </>
  );
};

export default StudentInteractiveDashboard;
