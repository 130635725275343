import { useState, useEffect } from "react";
import YouTube from "react-youtube";
import { Link, useLocation } from "react-router-dom";
import { Card, Row, Col, Accordion } from "react-bootstrap";

import PdfViewerBucket from "./PdfViewerBucket";
import Wrapper from "../../layout/wrapper";
import { setDataInLocalStorage } from "../../browser-storage";
import ReactPlayer from "react-player";
import { pdfjs, Document, Page } from "react-pdf";
import "./mentorlanding.css";
//this is required to load the pdf
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const InterviewPreparationBucket = ({}) => {
  const location = useLocation();
  const [scale, setScale] = useState(1.0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handleNextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrevPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? "disabled" : "clickable";
  const zoomInClass = isMaxZoom ? "disabled" : "clickable";

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };
  const pdfLocalDB = [
    {
      pdf_title: "Interview Basics",
      pdf_url:
        "https://lifeskillsbharat.mygreenhorn.in/PDF/Interview-Preparation/Interview-Basics.pdf",
    },
    {
      pdf_title: "Resume",
      pdf_url:
        "https://lifeskillsbharat.mygreenhorn.in/PDF/Interview-Preparation/Resume.pdf",
    },
    {
      pdf_title: "Frequently asked questions",
      pdf_url:
        "https://lifeskillsbharat.mygreenhorn.in/PDF/Interview-Preparation/Frequently-Asked-Questions.pdf",
    },
    {
      pdf_title: "On the interview day",
      pdf_url:
        "https://lifeskillsbharat.mygreenhorn.in/PDF/Interview-Preparation/On-the-Interview-Day.pdf",
    },
  ];

  const LongVideosLocalDB = [
    {
      video_title: "Interview Basics",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/Interview-basics.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/Thumbnail/Interview-basics.png",
    },
    {
      video_title: "Resume",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/resume.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/Thumbnail/resume.png",
    },
    {
      video_title: "Frequently asked questions",
      video_url: "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/FAQ.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/Thumbnail/Frequently-asked-questions.png",
    },
    {
      video_title: "On the interview day",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/On-the-interview-day.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Long_videos/Thumbnail/On-the-interview-day.png",
    },
  ];

  const ShortVideosLocalDB = [
    {
      video_title: "5 tips to build an outstanding resume",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/5-tips-to-build-an-outstanding-resume.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Thumbnails/5-tips-to-build-an-outstanding-resume.png",
    },
    {
      video_title: "How to stay confident in an interview",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/How-to-stay-confident-in-an-interview.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Thumbnails/How-to-stay-confident-in-an-interview.png",
    },
    {
      video_title: "What to do on the day of your interview",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/What-to-do-on-the-day-of-your-interview.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Thumbnails/What-to-do-on-the-day-of-your-interview.png",
    },
    {
      video_title: "Why do you want to work with us",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Why-do-you-want-to-work-with-us.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Thumbnails/Why-do-you-want-to-work-with-us.png",
    },

    {
      video_title: "Why should we hire you",
      video_url:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Why-should-we-hire-you.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Thumbnails/Why-should-we-hire-you.png",
    },

    {
      video_title: "Time when you failed",
      video_url: "https://cdn.mygreenhorn.in/newvideo/Time-when-you-failed.mp4",
      video_thumbnail:
        "https://cdn.mygreenhorn.in/MediaAssets/Short_videos/Thumbnails/Time-when-you-failed.png",
    },
  ];

  const [currentvidetoplay, setCurrentvideotoplay] = useState({
    title: "",
    videoURL: "",
    video_thumbnail: "",
  });
  const setSeletedvideotoplay = (item) => {
    console.log("item", item);
    setPlayer("Youtube");
    setCurrentvideotoplay({});
    setCurrentvideotoplay({
      title: item.video_title,
      videoURL: item.video_url,
      video_thumbnail: item.video_thumbnail,
    });
  };
  const setPDFtoshow = (item) => {
    console.log("item", item);
    setPlayer("PDF");
    setSelectPDF({
      PDFtitle: item.pdf_title,
      PDFLink: item.pdf_url,
      PDFDescription: item.pdfDescription,
    });

    setDataInLocalStorage("currentLocalPDFBucket", item.pdf_url);
  };
  const [selectPlayer, setPlayer] = useState("Youtube");
  const [selectPDF, setSelectPDF] = useState({
    PDFtitle: "",
    PDFLink: "",
    PDFDescription: "",
  });
  const [youtubePlaylist, setyoutubePlaylist] = useState([]);

  useEffect(() => {
    setCurrentvideotoplay({
      title: LongVideosLocalDB[0].video_title,
      videoURL: LongVideosLocalDB[0].video_url,
      video_thumbnail: LongVideosLocalDB[0].video_thumbnail,
    });
  }, []);

  return (
    <>
      <Wrapper>
        <section className="page__title-area pt-20 pb-90">
          <div className="page__title-shape">
            <img
              className="page-title-shape-5 d-none d-sm-block"
              src="/assets/img/page-title/page-title-shape-1.png"
              alt=""
            />
            <img
              className="page-title-shape-6"
              src="/assets/img/page-title/page-title-shape-6.png"
              alt=""
            />
            <img
              className="page-title-shape-7"
              src="/assets/img/page-title/page-title-shape-4.png"
              alt=""
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xxl-8 col-xl-8 col-lg-8">
                <div className="course__wrapper">
                  <div className="course__img w-img mb-30">
                    {selectPlayer == "Youtube" ? (
                      <>
                        <ReactPlayer
                          width="100%"
                          height={window.innerWidth <= 768 ? "200px" : "500px"}
                          url={currentvidetoplay.videoURL}
                          controls
                          light={currentvidetoplay.video_thumbnail}
                          config={{
                            file: {
                              attributes: { controlsList: "nodownload" },
                            },
                          }}
                          playing
                        />
                      </>
                    ) : null}

                    {selectPlayer == "PDF" ? (
                      <>
                        <div className="row">
                          <div className="text-center">
                            <div className="pdf-container">
                              <div className="desktop">
                                <div className="pdf-controls">
                                  <div class="d-flex flex-row bd-highlight mb-3">
                                    <div class="p-2 bd-highlight">
                                      <button
                                        className="e-btn"
                                        onClick={handlePrevPage}
                                        disabled={pageNumber === 1}
                                      >
                                        &lt;
                                      </button>
                                      <span
                                        className="e-btn"
                                        style={{
                                          marginLeft: 1,
                                          marginRight: 1,
                                        }}
                                      >
                                        {pageNumber} of {numPages}
                                      </span>
                                      <button
                                        className="e-btn"
                                        onClick={handleNextPage}
                                        disabled={pageNumber === numPages}
                                      >
                                        &gt;
                                      </button>
                                    </div>
                                    <div class="p-2 bd-highlight">
                                      <div>
                                        <div
                                          className="e-btn"
                                          onClick={zoomOut}
                                        >
                                          <i
                                            className={`fas fa-search-minus mx-3 ${zoomOutClass}`}
                                          />
                                        </div>
                                        <div
                                          style={{
                                            marginLeft: 1,
                                            marginRight: 1,
                                          }}
                                          className="e-btn"
                                        >
                                          <span>
                                            {(scale * 100).toFixed()}%
                                          </span>
                                        </div>
                                        <div className="e-btn" onClick={zoomIn}>
                                          <i
                                            className={`fas fa-search-plus mx-3 ${zoomInClass}`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div class="p-2 bd-highlight">
                                      <Link
                                        style={{
                                          marginLeft: 1,
                                          marginRight: 1,
                                        }}
                                        className="e-btn"
                                        to={`/pdf-interview-fullscreen-view`}
                                      >
                                        FullScreen
                                      </Link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mobile">
                                <div className="container-fluid">
                                  <div className="row justify-content-center">
                                    <div
                                      style={{ flexDirection: "row" }}
                                      className="col-12 col-md-6 d-flex align-items-center justify-content-between m-3 p-3"
                                    >
                                      <div>
                                        <button
                                          style={{ background: "#2b4eff" }}
                                          onClick={handlePrevPage}
                                          disabled={pageNumber === 1}
                                        >
                                          <i
                                            style={{
                                              color: "white",
                                              padding: 5,
                                            }}
                                            className="fa fa-arrow-left"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                        <span
                                          style={{
                                            marginLeft: 10,
                                            marginRight: 10,
                                          }}
                                        >
                                          {pageNumber} of {numPages}
                                        </span>
                                        <button
                                          style={{ background: "#2b4eff" }}
                                          onClick={handleNextPage}
                                          disabled={pageNumber === numPages}
                                        >
                                          <i
                                            style={{
                                              color: "white",
                                              padding: 5,
                                            }}
                                            className="fa fa-arrow-right"
                                            aria-hidden="true"
                                          ></i>
                                        </button>
                                      </div>
                                      <div
                                        style={{ flexDirection: "row" }}
                                        className="d-flex justify-content-between align-items-center"
                                      >
                                        <i
                                          style={{
                                            color: "#2b4eff",
                                            padding: 5,
                                          }}
                                          className={`fas fa-search-minus ${zoomOutClass}`}
                                          onClick={zoomOut}
                                        />
                                        &nbsp;
                                        <div>{(scale * 100).toFixed()}%</div>
                                        &nbsp;
                                        <i
                                          style={{
                                            color: "#2b4eff",
                                            padding: 5,
                                          }}
                                          className={`fas fa-search-plus ${zoomInClass}`}
                                          onClick={zoomIn}
                                        />
                                      </div>

                                      <div>
                                        <Link
                                          style={{
                                            marginLeft: 1,
                                            marginRight: 1,
                                          }}
                                          className="e-btn"
                                          to={`/pdf-interview-fullscreen-view`}
                                        >
                                          FullScreen
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Document
                                file={selectPDF.PDFLink}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={console.error}
                              >
                                <Page
                                  size="A4"
                                  style={{ backgroundColor: "tomato" }}
                                  pageNumber={pageNumber}
                                  fullScreen={true}
                                  width={"600"}
                                  scale={scale}
                                />
                              </Document>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div className="page__title-content mb-25">
                    <h5 className="page__title-3">
                      {selectPlayer == "Youtube" ? (
                        <p style={{ fontSize: 18, fontWeight: 600 }}>
                          {currentvidetoplay.title}
                        </p>
                      ) : null}

                      {selectPlayer == "PDF" ? (
                        <>
                          <p style={{ fontSize: 18, fontWeight: 600 }}>
                            {selectPDF.PDFtitle}
                          </p>
                        </>
                      ) : null}
                    </h5>
                  </div>
                  <div className="course__tab-2 mb-45">
                    <ul className="nav nav-tabs" id="courseTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="description-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#description"
                          type="button"
                          role="tab"
                          aria-controls="description"
                          aria-selected="true"
                        >
                          {" "}
                          <i className="icon_ribbon_alt"></i>{" "}
                          <span>Description</span>{" "}
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="course__tab-content mb-95">
                    <div className="tab-content" id="courseTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="description"
                        role="tabpanel"
                        aria-labelledby="description-tab"
                      >
                        <div className="course__description">
                          {selectPlayer == "Youtube" ? (
                            <p>
                              Learn everything about cracking your next big
                              career interview
                            </p>
                          ) : null}

                          {selectPlayer == "PDF" ? (
                            <>
                              <p>{selectPDF.PDFDescription}</p>
                            </>
                          ) : null}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="curriculum"
                        role="tabpanel"
                        aria-labelledby="curriculum-tab"
                      >
                        <div className="course__curriculum">
                          <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                              <Accordion.Header>lessons</Accordion.Header>
                              <Accordion.Body>
                                {youtubePlaylist.map((info, i) => (
                                  <div
                                    key={i}
                                    className="course__curriculum-content d-sm-flex justify-content-between align-items-center"
                                  >
                                    <div className="course__curriculum-info">
                                      {info.course_c_icon}
                                      <h3>
                                        {" "}
                                        <span>{info.video_title}</span>
                                        {info.course_c_text_2}
                                      </h3>
                                    </div>
                                    <div className="course__curriculum-meta">
                                      <span className="time">
                                        {" "}
                                        <i className={info.clock_icon}></i>
                                        {info.minute}
                                      </span>
                                      {info.questions && (
                                        <span className="question">
                                          {info.questions}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4">
                <div className="course__sidebar pl-40 p-relative">
                  <div
                    style={{ padding: 0 }}
                    className="course__sidebar-widget-2 white-bg mb-20"
                  >
                    <div className="divscroll">
                      <div style={{ width: "100%" }} className="e-btn">
                        Course PDFs
                      </div>
                      {pdfLocalDB.map((item) => {
                        return (
                          <>
                            <Card
                              onClick={() => setPDFtoshow(item)}
                              className="playlistcard"
                            >
                              <Row className="singleCard">
                                <Col>
                                  <p
                                    style={{ padding: 5 }}
                                    className="singlevideoList"
                                  >
                                    <i
                                      class="fa fa-file"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;
                                    {item.pdf_title}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        );
                      })}
                      <div style={{ width: "100%" }} className="e-btn">
                        Course Videos
                      </div>

                      {LongVideosLocalDB.map((item) => {
                        return (
                          <>
                            <Card
                              onClick={() => setSeletedvideotoplay(item)}
                              className="playlistcard"
                            >
                              <Row className="singleCard">
                                <Col>
                                  <p
                                    style={{ padding: 5 }}
                                    className="singlevideoList"
                                  >
                                    <i
                                      class="fa fa-play-circle"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;
                                    {item.video_title}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        );
                      })}
                      <div style={{ width: "100%" }} className="my-e-btn">
                        Additional Resources : YouTube
                      </div>
                      <Card className="playlistcard">
                        <Row className="singleCard">
                          <Col>
                            <a
                              href="https://www.youtube.com/watch?v=6CetTjU155Y"
                              target={"_blank"}
                            >
                              <p
                                style={{ padding: 5 }}
                                className="singlevideoList"
                              >
                                <i
                                  class="fa fa-play-circle"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;&nbsp; Good Example for How to Face an
                                Interview
                              </p>
                            </a>
                          </Col>
                        </Row>
                      </Card>
                      <Card className="playlistcard">
                        <Row className="singleCard">
                          <Col>
                            <a
                              href="https://www.youtube.com/watch?v=XHWjfwb0TaE"
                              target={"_blank"}
                            >
                              <p
                                style={{ padding: 5 }}
                                className="singlevideoList"
                              >
                                <i
                                  class="fa fa-play-circle"
                                  aria-hidden="true"
                                ></i>
                                &nbsp;&nbsp; How to beat applicant tracking
                                systems in 2021 (10 Ways)
                              </p>
                            </a>
                          </Col>
                        </Row>
                      </Card>

                      <div style={{ width: "100%" }} className="e-btn">
                        Quick Tips
                      </div>

                      {ShortVideosLocalDB.map((item) => {
                        return (
                          <>
                            <Card
                              onClick={() => setSeletedvideotoplay(item)}
                              className="playlistcard"
                            >
                              <Row className="singleCard">
                                <Col>
                                  <p
                                    style={{ padding: 5 }}
                                    className="singlevideoList"
                                  >
                                    <i
                                      class="fa fa-play-circle"
                                      aria-hidden="true"
                                    ></i>
                                    &nbsp;&nbsp;
                                    {item.video_title}
                                  </p>
                                </Col>
                              </Row>
                            </Card>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Wrapper>
    </>
  );
};

export default InterviewPreparationBucket;
