import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";

import ProgressBar from "../assessment/common/progressBar";
import { useDispatch, useSelector } from "react-redux";
import Card from "react-bootstrap/Card";
import moment from "moment";
import {
  AssessmentType,
  ScoreCardConstants,
} from "../../../redux/types/generic-constant";
import {
  getAssessmentResult,
  getModifiedAssessmentResult,
  DeleteTestResponseUsingTestId,
} from "../../../services/assessment";
import {
  AptitudeResponse,
  QuestionType,
  SoftSkillResponse,
} from "../../../redux/types/model/assessment/aptitude";
import { useReactToPrint } from "react-to-print";
import { getQueryParams } from "../../../helpers";
import { AppConfigEvent } from "../../../redux/types/redux-event";
import { toast } from "react-toastify";
import Wrapper from "../../../layout/wrapper";
import "../assessment/score-card.css";
import ScoreBar from "./score-bar";
import { Link, useNavigate, useLocation } from "react-router-dom";
import PrintScoreBar from "./print-score-bar";
import CareerInterestProgressBar from "./career-interest-progress-bar";
//Complete later
//import Header from "components/dashboard-header";

// interface StyledPros {
//   scorePer: number
//   paddingTop?: string
//   fontWeight?: number
//   fontSize?: number
//   paddLeft?: number
//   mrgBtm?: number
//   color?: string
// }

const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  //height: 70vh;
  padding: 35px;
  background: #ffffff;
  justify-content: center;
  //align-items: center;
`;

const SpanText = styled.div`
  display: flex;
  width:200px
  flex-direction: row;
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "23px")};
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : "500")};
  margin-bottom: ${(props) => (props.mrgBtm ? `${props.mrgBtm}` : "unset")};
  padding-left: ${(props) =>
    props.paddLeft ? `${props.paddLeft}px` : "unset"};
  color: ${(props) => (props.paddLeft ? `${props.color}` : "#3E3D43")};
  align-items: center;
`;

const OverallProgress = styled.div`
  display: flex;
  width: 100%;
  background: ${(props) =>
    props.scorePer <= 30
      ? "#EA5252"
      : props.scorePer > 31 && props.scorePer <= 50
      ? "#F9A825"
      : props.scorePer > 51 && props.scorePer <= 70
      ? "#0DADAD"
      : "#1C8D39"};
  height: 34px;
  color: #ffffff;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 25px;
`;

const DetailsBlock = styled.div`
  width: 100%;
  //padding-left: 20px;
  display: flex;
  flex-direction: column;
`;
const OverallBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
`;
const PerDiv = styled.div`
  width: 100%;
  background: #7e7e7e;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 10px 1px 10px;
  max-height: 60px;
  margin-left: 15px;
`;
const AllProgressbar = styled.div`
  width: 100%;
  display: flex;
  padding: 20px;
  border: 1px solid #c4c4c4;
  flex-direction: column;
  box-sizing: border-box;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.11);
  margin-top: 22px;
`;
const IndividualProgressBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: -7px;
`;

const MixScoreCardPage = () => {
  const componentRef = useRef();
  const navigate = useNavigate();

  let handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  // const { userProfile } = useSelector(({ userProfile }: RootState) => ({
  //   userProfile,
  // }))
  //check Once yash
  const userProfile = useSelector((state) => state.profile);
  console.log(userProfile);

  const [testData, setData] = useState({});
  const [displayQuiz, setdisplayQuiz] = useState(false);
  const assessmentType = getQueryParams("type");
  const [currentTestId, setCurrentTestId] = useState(null);
  useEffect(() => {
    const testId = getQueryParams("id");
    setCurrentTestId(testId);
    getTestData(assessmentType, testId);
  }, []);

  const [currentTrait, setCurrentTrait] = useState(null);
  const [CareerInterestTest, setCareerInterestTest] = useState(false);

  const [firstmax, setFirstMax] = useState(null);
  const [secondmax, setSecondMax] = useState(null);

  const getTestData = async (assessmentType, testId) => {
    try {
      //replace normal one with modified
      const res = await getModifiedAssessmentResult(assessmentType, testId);
      console.log("getTestData", res);
      const Mainresult = res.result;
      console.log("Mainresult", Mainresult);
      const resultType = Mainresult.type;
      console.log("resultType", resultType);

      if (resultType.A) {
        console.log("resultType.A", resultType.A);
        setCareerInterestTest(true);
        const A = resultType.A;
        const R = resultType.R;
        const I = resultType.I;
        const S = resultType.S;
        const E = resultType.E;
        const C = resultType.C;

        const scores = [
          { type: "A", value: A.score, name: "Artistic" },
          { type: "R", value: R.score, name: "Realistic" },
          { type: "I", value: I.score, name: "Investigative" },
          { type: "S", value: S.score, name: "Social" },
          { type: "E", value: E.score, name: "Enterprising" },
          { type: "C", value: C.score, name: "Conventional" },
        ];

        scores.sort((a, b) => b.value - a.value);

        const max1 = scores[0];
        const max2 = scores[1];

        setFirstMax(max1);
        setSecondMax(max2);

        console.log("First max:", max1);
        console.log("Second max:", max2);
      }

      if (resultType.EI) {
        const EI = resultType.EI;
        const EIpercentage = (EI.score / EI.totalQues) * 100;
        console.log("EIpercentage", EIpercentage);
        const EIvalue = EIpercentage > 50 ? "E" : "I";
        console.log("EIvalue", EIvalue);
        const SN = resultType.SN;
        const SNpercentage = (SN.score / SN.totalQues) * 100;
        console.log("SNpercentage", SNpercentage);
        const SNvalue = SNpercentage > 50 ? "S" : "N";
        console.log("SNvalue", SNvalue);
        console.log("SN", SN);
        const TF = resultType.TF;
        console.log("TF", TF);
        const TFpercentage = (TF.score / TF.totalQues) * 100;
        console.log("TFpercentage", TFpercentage);

        const TFvalue = TFpercentage > 50 ? "T" : "F";
        console.log("TFvalue", TFvalue);
        console.log("TF", TF);
        const JP = resultType.JP;
        const JPpercentage = (JP.score / JP.totalQues) * 100;
        console.log("JPpercentage", JPpercentage);
        const JPvalue = JPpercentage > 50 ? "J" : "P";
        console.log("JPvalue", JPvalue);
        console.log("JP", JP);

        const CurrentValue = EIvalue + SNvalue + TFvalue + JPvalue;
        console.log("CurrentValue", CurrentValue);
        setCurrentTrait(CurrentValue);
      }
      const Quiz = resultType.QUIZ;
      console.log("Quiz", Quiz);
      if (Quiz !== undefined) {
        const QuizAttemped = Quiz.attemptedQues;
        console.log("QuizAttemped", QuizAttemped);
        if (QuizAttemped !== 0) {
          setdisplayQuiz(true);
        }
      }

      setData(res);
    } catch (err) {
      console.log("err", err);
    }
  };

  function determineEIScore(score, attemptedQues, totalQues) {
    const percentage = (score / totalQues) * 100;
    return percentage > 50 ? "E" : "I";
  }

  const overallMarksPer =
    assessmentType === AssessmentType.APTITUDE
      ? (testData?.result?.rightAns / testData?.result?.totalQues) * 100
      : (testData?.result?.score / (testData?.result?.totalQues * 5)) * 100;
  const type =
    assessmentType === AssessmentType.APTITUDE
      ? testData?.result?.type
      : testData?.result?.type;
  const performanceOnPercentage = (percentage) => {
    if (percentage <= 30) return "Very Low";
    else if (percentage >= 31 && percentage <= 50) return "Low";
    else if (percentage >= 51 && percentage <= 70) return "Moderately High";
    return "High";
  };
  const retryAssessment = async () => {
    console.log("button clicked");
    console.log("currentTestId", currentTestId);
    const res = await DeleteTestResponseUsingTestId(currentTestId)
      .then((res) => {
        console.log("res", res);

        navigate("/behavioral-available-test", { replace: true });
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <>
      <Wrapper>
        {testData && testData.result && testData.result.attemptedQues == "0" ? (
          <Card className="mt-20">
            <p style={{ padding: 20 }}>
              You didn't submit your test properly, we are unable to generate
              your report
            </p>
            <div className="row text-center">
              <div className="">
                <button className="e-btn" onClick={() => retryAssessment()}>
                  Try Again
                </button>
              </div>
            </div>
          </Card>
        ) : (
          <>
            <div ref={componentRef}>
              {/* 
              
              
              
              
              
              */}
              {/* Use this condition to display the score card */}

              {/* 
              
              
              
              
              
              */}
              {displayQuiz !== true &&
              currentTrait == null &&
              CareerInterestTest == true ? (
                <>
                  <div className="container">
                    <div className="row mt-10">
                      <div className="col-md-6"></div>
                      <div className="col-md-6">
                        <div style={{ float: "right" }}>
                          <button onClick={handlePrint} className="e-btn">
                            Download PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ProgressContainer>
                    <div
                      className="HeadText"
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ flex: "1", fontSize: "24px" }}>
                        Career Interest Test
                        <br />
                        <p style={{ fontSize: "18px", fontWeight: 600 }}>
                          Name:{" "}
                          {userProfile.firstName + " " + userProfile.lastName}
                        </p>
                      </div>
                      <div
                        style={{
                          flex: "1",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <img
                          src="/assets/img/logo/logo.png"
                          alt="Logo"
                          style={{ width: "150px", margin: 10 }}
                        />
                      </div>
                    </div>
                  </ProgressContainer>
                  <div className="col-md-12 section page-break">
                    <div className="text-container">
                      <p className="sub-title-text">
                        Welcome to your career interest report! There&#39;s a
                        lot of information here, so take your time and read
                        through it at your own pace.
                      </p>
                      <p className="pt-20 sub-description-text">
                        There are 6 total interest areas, each with its own set
                        of typical work tasks, roles, and values. Some of these
                        interest areas will appeal to you, while others will be
                        less attractive. Choosing a career which is a good match
                        for your interest profile ensures that you enjoy your
                        daily work and get satisfaction out of your
                        accomplishments.
                      </p>
                      <p style={{ marginTop: 20 }}>So, let's get started!</p>
                      <img
                        className="whileprint center-image"
                        width={550}
                        src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp1.png"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="SoftSkillsContainer">
                    <div
                      className="mainContainer page-break"
                      style={{
                        paddingTop: 10,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      {type &&
                        Object.keys(type).length > 0 &&
                        Object.keys(type).map((key) => {
                          const score = type[key].score;

                          return (
                            <div className="row">
                              {ScoreCardConstants[key] === "R" ? (
                                <>
                                  <div className="row main-row-container">
                                    <p>
                                      <span className="span-heading">
                                        REALISTIC: Hands on knowledge, Building,
                                        Physical activity, Outdoors
                                      </span>

                                      <br />
                                      <span className="span-color-text-green">
                                        The bar shows how extraverted you are.
                                        The more to the right your score is, the
                                        higher you score on extraversion.
                                      </span>
                                    </p>
                                    <div className="row">
                                      <b>
                                        Score: {Math.round((score / 7) * 100)}
                                      </b>
                                    </div>
                                    <div className="print-row">
                                      <div className="print-column print-text-right">
                                        <p className="print-bar-text">0</p>
                                      </div>
                                      <div className="print-column print-progress-container">
                                        <div className="careerinterestprogressblock">
                                          <IndividualProgressBlock>
                                            <CareerInterestProgressBar
                                              isPrcntShow={true}
                                              scorePer={Math.round(
                                                (score / 7) * 100
                                              )}
                                            />
                                          </IndividualProgressBlock>
                                        </div>
                                      </div>
                                      <div className="print-column print-text-left">
                                        <p className="print-bar-text">100</p>
                                      </div>
                                    </div>

                                    <p>
                                      People with a Realistic (R) interest type
                                      prefers practical, hands-on work and
                                      enjoys solving mechanical or technical
                                      problems. For example, a mechanic who
                                      enjoys fixing cars or a construction
                                      worker building structures would thrive in
                                      this category. These individuals often
                                      find satisfaction in jobs where they can
                                      directly interact with tools, machines, or
                                      physical environments.
                                    </p>
                                    <div className="pt-40">
                                      Careers: The following are a few areas
                                      where such individuals can perform well.
                                      Please note that this is not an exhaustive
                                      list:
                                      <ul>
                                        <li className="score-li">
                                          Agriculture
                                        </li>
                                        <li className="score-li">Healthcare</li>
                                        <li className="score-li">
                                          Manufacturing
                                        </li>
                                        <li className="score-li">
                                          Construction
                                        </li>
                                        <li className="score-li pt-5">
                                          Industrial Engineering
                                        </li>
                                        <li className="score-li">
                                          Food and Hospitality
                                        </li>
                                        <li className="score-li">
                                          Public Safety
                                        </li>
                                      </ul>
                                    </div>
                                    <img
                                      className="whileprint center-image"
                                      style={{
                                        maxWidth: "400px",
                                        marginTop: 20,
                                      }}
                                      src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp2.jpg"
                                      alt=""
                                    />
                                  </div>
                                </>
                              ) : null}
                            </div>
                          );
                        })}
                    </div>

                    <div
                      className="mainContainer page-break"
                      style={{
                        paddingTop: 10,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      {type &&
                        Object.keys(type).length > 0 &&
                        Object.keys(type).map((key) => {
                          const score = type[key].score;

                          return (
                            <>
                              <div className="row">
                                {ScoreCardConstants[key] === "I" ? (
                                  <>
                                    <div className="row main-row-container">
                                      <p>
                                        <span className="span-heading">
                                          INVESTIGATIVE: Research, Learning,
                                          Science, Technology
                                        </span>
                                        <br />
                                        <span className="span-color-text-green">
                                          Those with an Investigative (I)
                                          orientation are curious, analytical,
                                          and enjoy problem-solving. They are
                                          typically drawn to fields like
                                          science, research, or technology.
                                        </span>
                                      </p>
                                      <div className="row">
                                        <b>
                                          Score: {Math.round((score / 7) * 100)}
                                        </b>
                                      </div>
                                      <div className="print-row">
                                        <div className="print-column print-text-right">
                                          <p className="print-bar-text">0</p>
                                        </div>
                                        <div className="print-column print-progress-container">
                                          <div className="careerinterestprogressblock">
                                            <IndividualProgressBlock>
                                              <CareerInterestProgressBar
                                                isPrcntShow={true}
                                                scorePer={(score / 7) * 100}
                                              />
                                            </IndividualProgressBlock>
                                          </div>
                                        </div>
                                        <div className="print-column print-text-left">
                                          <p className="print-bar-text">100</p>
                                        </div>
                                      </div>
                                      <div className="row pt-40">
                                        <p>
                                          Those with an Investigative (I)
                                          orientation are curious, analytical,
                                          and enjoy problem-solving. They are
                                          typically drawn to fields like
                                          science, research, or technology. A
                                          biologist studying ecosystems or a
                                          software developer solving complex
                                          algorithms are examples of
                                          investigative careers. These
                                          individuals enjoy roles that allow
                                          them to think critically and engage
                                          with data to uncover solutions.
                                        </p>
                                      </div>

                                      <div className="pt-40">
                                        <p>
                                          <strong>Careers:</strong> The
                                          following are a few areas where such
                                          individuals can perform well. Please
                                          note that this is not an exhaustive
                                          list:
                                        </p>
                                        <ul>
                                          <li className="score-li">
                                            Engineering
                                          </li>
                                          <li className="score-li">
                                            Mathematics
                                          </li>
                                          <li className="score-li">Medicine</li>
                                          <li className="score-li">
                                            Marine Biology
                                          </li>
                                          <li className="score-li">
                                            Chemistry
                                          </li>
                                          <li className="score-li">
                                            Economics
                                          </li>
                                          <li className="score-li">
                                            Psychology
                                          </li>
                                        </ul>
                                      </div>
                                      <img
                                        className="whileprint center-image"
                                        style={{
                                          maxWidth: "500px",
                                          marginTop: 50,
                                        }}
                                        src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp3.png"
                                        alt=""
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div
                      className="mainContainer page-break"
                      style={{
                        paddingTop: 10,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      {type &&
                        Object.keys(type).length > 0 &&
                        Object.keys(type).map((key) => {
                          const score = type[key].score;

                          return (
                            <>
                              <div className="row">
                                {ScoreCardConstants[key] === "A" ? (
                                  <>
                                    <div className="row main-row-container">
                                      <p>
                                        <span className="span-heading">
                                          ARTISTIC: Feeling, Passion, Design,
                                          Creation
                                        </span>
                                        <br />
                                      </p>
                                      <div className="row">
                                        <b>
                                          Score: {Math.round((score / 7) * 100)}
                                        </b>
                                      </div>
                                      <div className="print-row">
                                        <div className="print-column print-text-right">
                                          <p className="print-bar-text">0</p>
                                        </div>
                                        <div className="print-column print-progress-container">
                                          <div className="careerinterestprogressblock">
                                            <IndividualProgressBlock>
                                              <CareerInterestProgressBar
                                                isPrcntShow={true}
                                                scorePer={Math.round(
                                                  (score / 7) * 100
                                                )}
                                              />
                                            </IndividualProgressBlock>
                                          </div>
                                        </div>
                                        <div className="print-column print-text-left">
                                          <p className="print-bar-text">100</p>
                                        </div>
                                      </div>
                                      <div className="row pt-40">
                                        <p>
                                          Artistic (A) types are creative and
                                          expressive, preferring careers that
                                          allow for self-expression and
                                          innovation. A graphic designer
                                          creating visual content for a brand or
                                          an actor performing in theater would
                                          resonate with this type. Artistic
                                          individuals thrive in environments
                                          where creativity and freedom are
                                          encouraged, such as in the arts,
                                          writing, or media production.
                                        </p>
                                      </div>

                                      <div className="pt-40">
                                        <p>
                                          <strong>Careers:</strong> The
                                          following are a few areas where such
                                          individuals can perform well. Please
                                          note that this is not an exhaustive
                                          list:
                                        </p>
                                        <ul>
                                          <li className="score-li">
                                            Communications
                                          </li>
                                          <li className="score-li">
                                            Fashion Design
                                          </li>
                                          <li className="score-li">
                                            Fine and Performing Arts
                                          </li>
                                          <li className="score-li">
                                            Interior Design
                                          </li>
                                          <li className="score-li">
                                            Architecture
                                          </li>
                                          <li className="score-li">Media</li>
                                          <li className="score-li">
                                            Journalism
                                          </li>
                                        </ul>
                                      </div>
                                      <img
                                        className="whileprint center-image"
                                        style={{
                                          maxWidth: "500px",
                                          marginTop: 50,
                                        }}
                                        src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp4.png"
                                        alt=""
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div
                      className="mainContainer page-break"
                      style={{
                        paddingTop: 10,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      {type &&
                        Object.keys(type).length > 0 &&
                        Object.keys(type).map((key) => {
                          const score = type[key].score;

                          return (
                            <>
                              <div className="row">
                                {ScoreCardConstants[key] === "S" ? (
                                  <>
                                    <div className="row main-row-container">
                                      <p>
                                        <span className="span-heading">
                                          SOCIAL: Conveying, Understanding
                                          Others, Communicating, Relationships
                                        </span>
                                        <br />
                                      </p>
                                      <div className="row">
                                        <b>
                                          Score: {Math.round((score / 7) * 100)}
                                        </b>
                                      </div>
                                      <div className="print-row">
                                        <div className="print-column print-text-right">
                                          <p className="print-bar-text">0</p>
                                        </div>
                                        <div className="print-column print-progress-container">
                                          <div className="careerinterestprogressblock">
                                            <IndividualProgressBlock>
                                              <CareerInterestProgressBar
                                                isPrcntShow={true}
                                                scorePer={Math.round(
                                                  (score / 7) * 100
                                                )}
                                              />
                                            </IndividualProgressBlock>
                                          </div>
                                        </div>
                                        <div className="print-column print-text-left">
                                          <p className="print-bar-text">100</p>
                                        </div>
                                      </div>
                                      <div className="row pt-40">
                                        <p>
                                          Those who identify as Social(S) enjoy
                                          helping and interacting with others.
                                          They often pursue careers in teaching,
                                          healthcare, or counseling. For
                                          instance, a nurse caring for patients
                                          in a hospital or a teacher shaping
                                          young minds in a classroom exemplifies
                                          the Social personality. These
                                          individuals find fulfillment in roles
                                          that enable them to make a positive
                                          impact on others’ lives.
                                        </p>
                                      </div>

                                      <div className="pt-40">
                                        <p>
                                          <strong>Careers:</strong> The
                                          following are a few areas where such
                                          individuals can perform well. Please
                                          note that this is not an exhaustive
                                          list:
                                        </p>
                                        <ul>
                                          <li className="score-li">
                                            Customer Service
                                          </li>
                                          <li className="score-li">
                                            Public Relations
                                          </li>
                                          <li className="score-li">
                                            Education
                                          </li>
                                          <li className="score-li">
                                            Human Resources
                                          </li>
                                          <li className="score-li">
                                            Counseling
                                          </li>
                                          <li className="score-li">Nursing</li>
                                          <li className="score-li">
                                            Childcare
                                          </li>
                                        </ul>
                                      </div>
                                      <img
                                        className="whileprint center-image"
                                        style={{
                                          maxWidth: "600px",
                                          marginTop: 150,
                                        }}
                                        src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp5.png"
                                        alt=""
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div
                      className="mainContainer page-break"
                      style={{
                        paddingTop: 10,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      {type &&
                        Object.keys(type).length > 0 &&
                        Object.keys(type).map((key) => {
                          const score = type[key].score;

                          return (
                            <>
                              <div className="row">
                                {ScoreCardConstants[key] === "E" ? (
                                  <>
                                    <div className="row main-row-container">
                                      <p>
                                        <span className="span-heading">
                                          ENTERPRISING: Taking Action,
                                          Understanding Projects, Leading,
                                          Managing
                                        </span>
                                        <br />
                                      </p>
                                      <div className="row">
                                        <b>
                                          Score: {Math.round((score / 7) * 100)}
                                        </b>
                                      </div>
                                      <div className="print-row">
                                        <div className="print-column print-text-right">
                                          <p className="print-bar-text">0</p>
                                        </div>
                                        <div className="print-column print-progress-container">
                                          <div className="careerinterestprogressblock">
                                            <IndividualProgressBlock>
                                              <CareerInterestProgressBar
                                                isPrcntShow={true}
                                                scorePer={Math.round(
                                                  (score / 7) * 100
                                                )}
                                              />
                                            </IndividualProgressBlock>
                                          </div>
                                        </div>
                                        <div className="print-column print-text-left">
                                          <p className="print-bar-text">100</p>
                                        </div>
                                      </div>
                                      <div className="row pt-40">
                                        <p>
                                          The Enterprising (E) type is
                                          ambitious, persuasive, and enjoys
                                          leadership or entrepreneurial roles. A
                                          corporate manager directing a team or
                                          a startup funder building a new
                                          business are examples of enterprising
                                          careers. These individuals excel in
                                          environments where they can lead,
                                          persuade, and drive projects forward.
                                        </p>
                                      </div>

                                      <div className="pt-40">
                                        <p>
                                          <strong>Careers:</strong> The
                                          following are a few areas where such
                                          individuals can perform well. Please
                                          note that this is not an exhaustive
                                          list:
                                        </p>
                                        <ul>
                                          <li className="score-li">
                                            Sales and Marketing
                                          </li>
                                          <li className="score-li">Law</li>
                                          <li className="score-li">
                                            Political Science
                                          </li>
                                          <li className="score-li">
                                            International Science
                                          </li>
                                          <li className="score-li">
                                            Business and Management
                                          </li>
                                          <li className="score-li">
                                            Entrepreneurship
                                          </li>
                                        </ul>
                                      </div>
                                      <img
                                        className="whileprint center-image"
                                        style={{ maxWidth: "280px" }}
                                        src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp7.png"
                                        alt=""
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div
                      className="mainContainer page-break"
                      style={{
                        paddingTop: 10,
                        paddingLeft: 40,
                        paddingRight: 40,
                      }}
                    >
                      {type &&
                        Object.keys(type).length > 0 &&
                        Object.keys(type).map((key) => {
                          const score = type[key].score;

                          return (
                            <>
                              <div className="row">
                                {ScoreCardConstants[key] === "C" ? (
                                  <>
                                    <div className="row main-row-container">
                                      <p>
                                        <span className="span-heading">
                                          CONVENTIONAL: Planning, Structuring,
                                          Processing Data, Figures
                                        </span>
                                        <br />
                                      </p>
                                      <div className="row">
                                        <b>
                                          Score: {Math.round((score / 7) * 100)}
                                        </b>
                                      </div>
                                      <div className="print-row">
                                        <div className="print-column print-text-right">
                                          <p className="print-bar-text">0</p>
                                        </div>
                                        <div className="print-column print-progress-container">
                                          <div className="careerinterestprogressblock">
                                            <IndividualProgressBlock>
                                              <CareerInterestProgressBar
                                                isPrcntShow={true}
                                                scorePer={Math.round(
                                                  (score / 7) * 100
                                                )}
                                              />
                                            </IndividualProgressBlock>
                                          </div>
                                        </div>
                                        <div className="print-column print-text-left">
                                          <p className="print-bar-text">100</p>
                                        </div>
                                      </div>
                                      <div className="row pt-40">
                                        <p>
                                          Those with a Conventional(C) interest
                                          type prefer structured,
                                          detail-oriented work. They are
                                          well-suited for careers in
                                          administration, finance, or data
                                          management. An accountant balancing
                                          financial records or an office manager
                                          overseeing office operations would
                                          thrive in conventional roles. These
                                          individuals excel in organized,
                                          systematic environments where
                                          attention to detail is key.
                                        </p>
                                      </div>

                                      <div className="pt-40">
                                        <p>
                                          <strong>Careers:</strong> The
                                          following are a few areas where such
                                          individuals can perform well. Please
                                          note that this is not an exhaustive
                                          list:
                                        </p>
                                        <ul>
                                          <li className="score-li">
                                            Accounting
                                          </li>
                                          <li className="score-li">Auditing</li>
                                          <li className="score-li">
                                            Insurance and Underwriting
                                          </li>
                                          <li className="score-li">
                                            Administration
                                          </li>
                                          <li className="score-li">Payroll</li>
                                          <li className="score-li">
                                            Banking Operations
                                          </li>
                                          <li className="score-li">
                                            Data Processing
                                          </li>
                                        </ul>
                                      </div>
                                      <img
                                        className="whileprint center-image"
                                        style={{ maxWidth: "400px" }}
                                        src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp8.png"
                                        alt=""
                                      />
                                    </div>
                                  </>
                                ) : null}
                              </div>
                            </>
                          );
                        })}
                    </div>
                    <div
                      className="text-container mainContainer"
                      style={{ padding: 10, marginBottom: 20 }}
                    >
                      <p>
                        Based on your scores, your <b>Primary Interest</b> type
                        is <b>{firstmax.name}</b>
                      </p>

                      <p style={{ marginTop: 10 }}>
                        Please refer to the corresponding section to learn more
                        about your career interests
                      </p>
                      <br />
                      <p>
                        Based on your scores, your <b>Secondary Interest</b>{" "}
                        type is <b>{secondmax.name}</b>
                      </p>

                      <p style={{ marginTop: 10 }}>
                        Please refer to the corresponding section to know more
                      </p>
                      <br />
                      <p>
                        Your <b>Primary Interest</b> type is also called your
                        career type. Your career type describes the kind of job
                        tasks and activities you enjoy doing, as well as what
                        motivates and satisfies you at work. Certain personality
                        traits and characteristics are associated with each
                        career type.
                      </p>
                      <br />
                      <p>
                        Your <b>Secondary Interest</b> type shows what sorts of
                        interests you may have, beyond your primary interest
                        area. You can use your secondary interest area to get
                        additional ideas and information about the career that
                        may fit you.
                      </p>
                      <img
                        className="whileprint center-image"
                        style={{ maxWidth: "700px", marginTop: 50 }}
                        src="https://test1.mygreenhorn.in/GHcontent/careerreport/sp6.png"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ) : null}
            </div>
          </>
        )}
      </Wrapper>
    </>
  );
};
export default MixScoreCardPage;
